.abi-form {
    margin-top: 2rem
}

.abi-form-title {
    font-family: "NNNittiGrotesk-Heading";
    font-size: 2.375rem
}

@media print,
screen and (min-width: 64em) {
    .abi-form .fields-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem
    }
}

.abi-form .input-wrapper:not(.checkbox) {
    width: 100%;
    display: inline-block
}

@media print,
screen and (min-width: 64em) {
    .abi-form .input-wrapper:not(.checkbox) {
        width: 40%;
        margin-right: 4%
    }
}

.abi-form-admin {
    margin-bottom: 1.875rem
}

.abi-form-admin-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle
}

.abi-form-submit {
    padding: 1rem 2rem
}

.complaint-form {
    background: #fff;
    max-height: none
}

.complaint-form *:not(.row):not(.tooltip-content) {
    max-width: 100%
}

.complaint-form input:not([type=checkbox]):not([type=radio]) {
    display: block;
    width: 100%
}

.complaint-form .checkbox {
    color: #8b8b8b
}

.complaint-form .no-menu-section .hero-unit {
    padding-bottom: 2.5rem
}

.complaint-form .form-horizontal fieldset {
    margin-bottom: 3.125rem
}

.complaint-form .form-horizontal fieldset h4 {
    margin-top: 1.625rem;
    margin-bottom: 1.625rem
}

.complaint-form .form-horizontal fieldset .control-group+h4 {
    margin-top: 2.875rem
}

.complaint-form .form-horizontal input[type=text],
.complaint-form .form-horizontal input[type=password],
.complaint-form .form-horizontal input[type=email],
.complaint-form .form-horizontal input[type=number],
.complaint-form .form-horizontal select,
.complaint-form .form-horizontal textarea {
    width: 100%
}

.complaint-form .form-horizontal .control-label {
    width: 13.75rem !important;
    padding-top: .5rem;
    text-align: left;
    font-size: 1rem
}

.complaint-form .form-horizontal .control-group {
    margin-bottom: .625rem
}

.complaint-form .form-horizontal .control-label.radio-label {
    padding-top: 0;
    font-weight: bold;
    color: #ee7f00;
    font-size: 1.125rem;
    line-height: 1
}

.complaint-form .form-horizontal .input-append.date {
    width: 100%
}

.complaint-form .form-horizontal .control-group.error input,
.complaint-form .form-horizontal .control-group.error textarea {
    border-color: red
}

.section-form .circle {
    display: inline-block;
    margin-right: 10px;
    line-height: 43px;
    color: #666;
    border-radius: 13px
}

.section-form .circle:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: #ee7101 url(assets/icons-white.4f01b1b29e8c4edcd4043cad0bff1f7f.png) repeat-y 0 0
}

.section-form .circle:hover {
    color: #ee7101
}

.section-form .circle.small {
    line-height: 26px
}

.section-form .circle.small:before {
    width: 26px;
    height: 26px;
    margin-top: -1px;
    margin-right: 7px;
    border-radius: 13px;
    background-image: url(assets/icons-small-white.20cf0a03e96a15260dbf4f83ac985978.png)
}

.section-form .circle.small.right:before {
    background-image: url(assets/icons-small-white-horizontal.0ec1e6aa87ecbb4c51fb005c2ef9534d.png);
    background-position: 0 -50px;
    background-repeat: repeat-x
}

.section-form .circle.small.cross:before {
    background-image: url(assets/icons-small-white.20cf0a03e96a15260dbf4f83ac985978.png);
    background-position: -350px -51px
}

.section-form .conditional-section {
    position: relative
}

.section-form .conditional-section [data-show] {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease 0ms, visibility 200ms ease 0ms;
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.section-form .conditional-section [data-show].active {
    position: static;
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: all 200ms ease 200ms, visibility 200ms ease 200ms
}

.section-form .conditional-section .nice-select .option.disabled {
    display: none !important;
}

.section-form .file-upload-button {
    background: none;
    border: none;
    outline: 0;
    padding: 0
}

.section-form .file-upload-remove,
.section-form .file-upload-filename,
.section-form .filled .file-upload-button {
    display: none
}

.section-form .filled .file-upload-remove,
.section-form .filled .file-upload-filename {
    display: block
}

.section-form .file-upload-remove {
    float: right
}

.section-form .file-upload-filename {
    min-height: 2.125rem;
    padding: .375rem 0 .5rem 2.25rem;
    line-height: 1.625rem;
    background: url(assets/ico-doc.92b902376853794d3aa60fc34870d1ae.png) no-repeat 0 5px !important
}

.section-form .file-upload-filename span {
    word-break: break-all;
    word-break: break-word
}

.section-form .file-upload-filename em {
    font-style: normal;
    color: #999
}

.section-form .filled+.file-upload-wrapper {
    margin-top: 1.75rem
}

.file-upload-wrapper {
    &.error {
        .nn-validation-message {
            color: #e64415;
        }
    }
}

.section-form .filled+.filled {
    margin-top: 0
}

.global-error .error {
    display: block;
    padding: .25rem 0 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #e64415
}

.global-error .error .big {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

@media (max-width: 979px) {
    .complaint-form .form-horizontal .narrow {
        padding-right: 0
    }

    .complaint-form .form-horizontal .control-group {
        width: 100%
    }

    .complaint-form .form-horizontal .controls {
        margin-left: 0
    }

    .complaint-form .form-horizontal .control-label.radio-label {
        width: 100% !important
    }
}

.section-form .form-thx {
    display: none
}

.section-form .form-thx {
    margin-top: -0.8rem;
    text-align: center;
    font-size: 1.31rem
}

.section-form-form .form-thx p:first-child {
    font-size: 1.5rem;
    margin-bottom: 1rem
}

.section-form .form-thx p {
    margin-bottom: 1.15rem !important
}

@media screen and (max-width: 767px) {
    .complaint-form .no-menu-section .hero-unit img {
        display: none
    }

    .complaint-form .form-horizontal .control-label {
        width: 100% !important
    }

    .complaint-form .form-horizontal .control-group input[type=text] {
        display: inline-block
    }

    .section-form .file-upload-wrapper {
        clear: both
    }
}

@media screen and (min-width: 768px)and (max-width: 979px) {
    .complaint-form .no-menu-section .hero-unit img {
        margin-top: 0
    }

    .complaint-form .form .control-group {
        width: 100%
    }
}

.contact-helpline {
    background-color: #fff
}

.container-section .contact-helpline {
    margin-right: -13px;
    margin-left: -13px
}

@media print,
screen and (min-width: 40em) {
    .container-section .contact-helpline {
        margin-right: -13px;
        margin-left: -13px
    }
}

@media screen and (min-width: 85.375em) {
    .contact-helpline .input-wrapper.checkbox.required:after {
        margin-left: .125rem
    }
}

.contact-helpline input[type=checkbox]+label,
.contact-helpline .tooltip.no-icon {
    font-size: .9rem
}

.contact-helpline input[type=checkbox]+label {
    color: #8b8b8b;
    line-height: 1.6rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    max-width: calc(100% - 60px);
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media print,
screen and (min-width: 40em) {
    .contact-helpline input[type=checkbox]+label {
        padding-top: .75rem;
        padding-bottom: .75rem
    }
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline input[type=checkbox]+label {
        padding-top: .25rem;
        padding-bottom: .25rem;
        line-height: 1
    }
}

.contact-helpline .contact-helpline--header {
    text-align: center
}

.contact-helpline .background-wrapper {
    background-color: #f4f4f4;
    padding: 3rem 0 0
}

.contact-helpline--title,
.contact-helpline .section-header {
    font-family: "NNNittiGrotesk-Heading";
    font-size: 2.25rem;
    color: #ee7f00;
    margin-bottom: 1.5rem;
    width: 100%
}

@media print,
screen and (min-width: 40em) {

    .contact-helpline--title,
    .contact-helpline .section-header {
        font-size: 3.125rem
    }
}

@media print,
screen and (min-width: 64em) {

    .contact-helpline--title,
    .contact-helpline .section-header {
        margin-bottom: 1rem
    }
}

@media screen and (min-width: 100em) {

    .contact-helpline--title,
    .contact-helpline .section-header {
        margin-bottom: 1.5625rem
    }
}

.contact-helpline--subtitle,
.contact-helpline .section-description>p {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    width: 100%
}

@media print,
screen and (min-width: 40em) {

    .contact-helpline--subtitle,
    .contact-helpline .section-description>p {
        margin-bottom: 3.75rem
    }
}

.contact-helpline .input-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 1rem
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline .input-section {
        flex-direction: row;
        margin-bottom: 0
    }
}

.contact-helpline .input-section .input-wrapper {
    flex: 1 1 auto
}

.contact-helpline .input-section .input-wrapper .input-name,
.contact-helpline .input-section .input-wrapper .input-phone,
.contact-helpline .input-section .input-wrapper .input-with-tooltip {
    width: 100%
}

.contact-helpline .input-section .input-wrapper [class^=input-] {
    height: 3.125rem
}

.contact-helpline .checkbox,
.contact-helpline .helpline-admin {
    font-size: .875rem;
    text-align: left;
    display: block;
    line-height: 1.125rem;
    margin-bottom: 1.875rem
}

@media print,
screen and (min-width: 64em) {

    .contact-helpline .checkbox,
    .contact-helpline .helpline-admin {
        line-height: 1.28;
        margin-bottom: 1rem
    }
}

@media screen and (min-width: 100em) {

    .contact-helpline .checkbox,
    .contact-helpline .helpline-admin {
        line-height: 1.125rem
    }
}

.contact-helpline .checkbox-text,
.contact-helpline .helpline-admin-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 3.75rem);
    vertical-align: top
}

.contact-helpline .helpline-admin .nn-tooltip-trigger {
    display: inline;
    word-break: break-word;
    vertical-align: top
}

.contact-helpline .large-shrink {
    flex: 0 0 100%
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline .large-shrink {
        flex: 0 0 auto
    }
}

.contact-helpline .large-shrink .btn {
    width: 8.75rem;
    border: none
}

@media screen and (max-width: 39.99875em) {
    .contact-helpline .large-shrink .btn {
        width: 100%
    }
}

.contact-helpline.__with-counter {
    margin-bottom: 4rem
}

@media screen and (max-width: 63.99875em) {
    .contact-helpline:not(.__with-counter) .large-shrink .btn {
        margin-bottom: 2rem
    }
}

.contact-helpline .checkbox-section-helpline {
    text-align: left
}

.contact-helpline.__slim form>.row.collapse {
    flex-direction: column
}

.contact-helpline.__slim.__with-counter {
    margin-bottom: 0
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline.__slim {
        clip-path: inset(0 0 0 0)
    }
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline.__slim .background-wrapper {
        transition: all .5s ease-in-out;
        transform: translateX(-100%)
    }
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline.__slim .background-wrapper.active {
        transform: none
    }
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline.__slim .contact-helpline--header {
        padding-left: 1.875rem
    }
}

.contact-helpline.__slim .contact-helpline--title,
.contact-helpline.__slim .section-header {
    text-align: left
}

@media screen and (max-width: 85.37375em) {

    .contact-helpline.__slim .contact-helpline--title,
    .contact-helpline.__slim .section-header {
        text-align: center;
        margin-left: 0
    }
}

.contact-helpline.__slim .contact-helpline--subtitle,
.contact-helpline.__slim .section-description>p {
    margin-bottom: 1.875rem;
    text-align: left
}

@media screen and (max-width: 85.37375em) {

    .contact-helpline.__slim .contact-helpline--subtitle,
    .contact-helpline.__slim .section-description>p {
        text-align: center;
        margin-left: 0
    }
}

.contact-helpline.__slim .input-wrapper .tooltip {
    padding-bottom: 1px
}

.contact-helpline.__slim .large-expand {
    flex: 1 1 auto
}

.contact-helpline.__slim .input-section {
    flex-direction: column;
    width: 100%
}

.contact-helpline.__slim .input-section .input-name {
    width: 100%
}

.contact-helpline.__slim .input-section .input-phone {
    width: 100%;
    margin-left: 0;
    margin-right: 0
}

.contact-helpline.__slim .shrink {
    width: 100%;
    padding: 0
}

@media screen and (max-width: 85.37375em) {
    .contact-helpline.__slim .shrink .send-button-helpline {
        width: 100%
    }
}

.contact-helpline.__slim .contact-helpline-counter-text {
    font-size: .9375rem
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline.__slim .contact-helpline-counter-text {
        font-size: 1rem
    }
}

.contact-helpline.__slim:not(.__with-counter) .btn[type=submit] {
    margin-bottom: 2rem
}

.contact-helpline-counter {
    display: flex;
    align-items: center;
    border-top: 1px solid #ee7f00;
    border-bottom: 1px solid #ee7f00;
    margin: 2.5rem auto 0;
    transform: translateY(50%);
    background-color: #fff;
    width: 100%;
    justify-content: center
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline-counter {
        margin: 0 auto
    }
}

.contact-helpline-counter-text {
    font-family: "NNNittiGrotesk-Heading";
    font-size: .9375rem;
    padding: 1rem .9375rem .9375rem;
    color: #414141
}

@media print,
screen and (min-width: 40em) {
    .contact-helpline-counter-text {
        font-size: 1.125rem;
        padding: .9375rem
    }
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline-counter-text {
        padding: .9375rem
    }
}

.contact-helpline-counter-number {
    border-radius: 50%;
    min-width: 7.5rem;
    min-height: 7.5rem;
    margin: -1rem 0;
    border: solid 2px #ee7f00;
    background-color: #fff
}

.contact-helpline-counter-number .numbers-send-form {
    font-size: 2rem;
    color: #ee7f00;
    font-family: "NNNittiGrotesk-Heading";
    margin: 2.6rem auto;
    text-align: center
}

.contact-helpline.ppk .helpline-admin .nn-tooltip-trigger {
    vertical-align: top
}

.contact-helpline .success-message {
    padding: 0 0 2rem
}

.contact-helpline .success-message .message-header {
    font-family: "NNNittiGrotesk-Heading";
    font-size: 2.375rem;
    color: #f39100;
    margin-bottom: 2rem
}

.contact-helpline.callback-form.__slim .section-header-wrapper .columns {
    padding: 0
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline.callback-form.__slim .section-header-wrapper .columns .section-header {
        text-align: left;
        margin-bottom: 1.5625rem
    }
}

@media print,
screen and (min-width: 40em) {
    .contact-helpline:not(.ppk) .large-shrink .btn {
        width: 9.75rem
    }
}

.main-slider .main-slider--slide .contact-helpline.form-wrapper.__slim .contact-helpline--title {
    margin-bottom: 0
}

.main-slider .contact-helpline.__slim {
    padding: 1.875rem 1.875rem 3.875rem
}

@media print,
screen and (min-width: 40em) {
    .main-slider .contact-helpline.__slim {
        padding: 1.875rem
    }
}

@media print,
screen and (min-width: 64em) {
    .main-slider .contact-helpline.__slim {
        clip-path: none
    }
}

.main-slider .contact-helpline.__slim .nn-widget {
    width: 100%
}

.main-slider .contact-helpline.__slim .nn-widget input[type=checkbox]+label {
    line-height: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Form-submit {
    margin-top: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Input-nnWidgetFormGroup {
    margin-top: 1.25rem
}

.contact-helpline .nn-widget .nn-widget__components-Input-nnWidgetFormInput,
.contact-helpline .nn-widget .nn-widget__components-Select-components-DesktopSelect-nnSelect {
    line-height: 1.25rem;
    font-size: 1rem;
    padding: 1.5rem 1rem .5rem
}

.contact-helpline .nn-widget input:placeholder-shown:not(:focus)+.nn-widget__components-Input-nnWidgetFormLabel,
.contact-helpline .nn-widget .nn-widget__components-Select-components-DesktopSelect-nnSelectLabelBig {
    top: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Select-components-DesktopSelect-nnSelectDropdownOption {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: .875rem 3.75rem .875rem 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Select-components-DesktopSelect-nnSelectArrowInner {
    padding: .375rem
}

.contact-helpline .nn-widget .nn-widget__components-Input-nnWidgetFormLabel {
    font-size: .75rem;
    left: 1rem;
    top: .3125rem
}

.contact-helpline .nn-widget .nn-widget__components-Input-nnWidgetFormUserTip {
    line-height: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Input-nnWidgetFormErrorFeedback {
    position: static;
    margin: .25rem 0;
    line-height: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Statements-wrapper {
    margin-top: 1rem;
    margin-bottom: .5rem
}

.contact-helpline .nn-widget .nn-widget__components-Statements-wrapper .nn-widget__components-Popup-button {
    height: auto;
    font-size: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Statements-statement .nn-widget__components-Popup-button {
    height: auto;
    font-size: 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Checkbox-wrapper {
    margin-top: 1.5rem
}

.contact-helpline .nn-widget .nn-widget__components-Checkbox-wrapper label {
    left: 0;
    padding-top: 0
}

.contact-helpline .nn-widget .nn-widget__components-Checkbox-wrapper label:before {
    content: none
}

.contact-helpline .nn-widget .nn-widget__components-Checkbox-wrapper label:after {
    top: 0;
    left: 3px;
    color: transparent
}

.contact-helpline .nn-widget .nn-widget__components-Checkbox-wrapper input[type=checkbox]+label {
    padding-left: 2.25rem;
    white-space: inherit;
    max-width: none;
    line-height: 1.5rem
}

.contact-helpline .nn-widget .nn-widget__components-Pages-ssl {
    margin: .5rem 0 .5rem 1rem
}

.contact-helpline .nn-widget .nn-widget__components-Form-button {
    font-size: 1.125rem
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline .nn-widget .nn-widget__components-Form-button {
        margin-top: .25rem
    }
}

@media print,
screen and (min-width: 64em) {
    .contact-helpline .nn-widget .nn-widget__components-Statements-horizontalWrapper {
        margin-left: 2.25rem;
        margin-top: .25rem
    }
}

.form-constructor .form-selection-container.input-wrapper .selection-fieldset {
    margin: 0
}

.form-constructor .form-selection-container.input-wrapper .dropdown-trigger {
    line-height: 1;
    font-size: 1rem;
    padding: 1rem 1.25rem;
    border: 1px solid #cac7c7;
    border-radius: 2px;
    margin-bottom: 1.25rem;
    height: auto;
    color: #cac7c7
}

.nnik-complaint-form {
    background: #fff;
    max-height: none
}

.nnik-complaint-form *:not(.row):not(.tooltip-content) {
    max-width: 100%
}

.nnik-complaint-form input:not([type=checkbox]):not([type=radio]) {
    display: block;
    width: 100%
}

.nnik-complaint-form .checkbox {
    color: #8b8b8b
}

.nnik-complaint-form .no-menu-section .hero-unit {
    padding-bottom: 2.5rem
}

.nnik-complaint-form .form-horizontal fieldset {
    margin-bottom: 3.125rem
}

.nnik-complaint-form .form-horizontal fieldset h4 {
    margin-top: 1.625rem;
    margin-bottom: 1.625rem
}

.nnik-complaint-form .form-horizontal fieldset .control-group+h4 {
    margin-top: 2.875rem
}

.nnik-complaint-form .form-horizontal input[type=text],
.nnik-complaint-form .form-horizontal input[type=password],
.nnik-complaint-form .form-horizontal input[type=email],
.nnik-complaint-form .form-horizontal input[type=number],
.nnik-complaint-form .form-horizontal select,
.nnik-complaint-form .form-horizontal textarea {
    width: 100%
}

.nnik-complaint-form .form-horizontal .control-label {
    width: 13.75rem !important;
    padding-top: .5rem;
    text-align: left;
    font-size: 1rem
}

.nnik-complaint-form .form-horizontal .control-group {
    margin-bottom: .625rem
}

.nnik-complaint-form .form-horizontal .control-label.radio-label {
    padding-top: 0;
    font-weight: bold;
    color: #ee7f00;
    font-size: 1.125rem;
    line-height: 1
}

.nnik-complaint-form .form-horizontal .input-append.date {
    width: 100%
}

.nnik-complaint-form .form-horizontal .control-group .agreements {
    margin-bottom: 1rem
}

.nnik-complaint-form .form-horizontal .control-group.error input,
.nnik-complaint-form .form-horizontal .control-group.error textarea {
    border-color: red
}

.nnik-complaint-form .circle {
    display: inline-block;
    margin-right: 10px;
    line-height: 43px;
    color: #666
}

.nnik-complaint-form .circle:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: #ee7101 url(assets/icons-white.4f01b1b29e8c4edcd4043cad0bff1f7f.png) repeat-y 0 0
}

.nnik-complaint-form .circle:hover {
    color: #ee7101
}

.nnik-complaint-form .circle.small {
    line-height: 26px
}

.nnik-complaint-form .circle.small:before {
    width: 26px;
    height: 26px;
    margin-top: -1px;
    margin-right: 7px;
    border-radius: 13px;
    background-image: url(assets/icons-small-white.20cf0a03e96a15260dbf4f83ac985978.png)
}

.nnik-complaint-form .circle.small.right:before {
    background-image: url(assets/icons-small-white-horizontal.0ec1e6aa87ecbb4c51fb005c2ef9534d.png);
    background-position: 0 -50px;
    background-repeat: repeat-x
}

.nnik-complaint-form .circle.small.cross:before {
    background-image: url(assets/icons-small-white.20cf0a03e96a15260dbf4f83ac985978.png);
    background-position: -350px -51px
}

.nnik-complaint-form .conditional-section {
    position: relative
}

.nnik-complaint-form .conditional-section [data-show] {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease 0ms, visibility 200ms ease 0ms;
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.nnik-complaint-form .conditional-section [data-show].active {
    position: static;
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: all 200ms ease 200ms, visibility 200ms ease 200ms
}

.nnik-complaint-form .file-upload-button {
    background: none;
    border: none;
    outline: 0;
    padding: 0
}

.nnik-complaint-form .file-upload-remove,
.nnik-complaint-form .file-upload-filename,
.nnik-complaint-form .filled .file-upload-button {
    display: none
}

.nnik-complaint-form .filled .file-upload-remove,
.nnik-complaint-form .filled .file-upload-filename {
    display: block
}

.nnik-complaint-form .file-upload-remove {
    float: right
}

.nnik-complaint-form .file-upload-filename {
    min-height: 2.125rem;
    padding: .375rem 0 .5rem 2.25rem;
    line-height: 1.625rem;
    background: url(assets/ico-doc.92b902376853794d3aa60fc34870d1ae.png) no-repeat 0 5px !important
}

.nnik-complaint-form .file-upload-filename span {
    word-break: break-all;
    word-break: break-word
}

.nnik-complaint-form .file-upload-filename em {
    font-style: normal;
    color: #999
}

.nnik-complaint-form .filled+.file-upload-wrapper {
    margin-top: 1.75rem;
}

.nnik-complaint-form .filled+.filled {
    margin-top: 0
}

@media (max-width: 979px) {
    .nnik-complaint-form .form-horizontal .narrow {
        padding-right: 0
    }

    .nnik-complaint-form .form-horizontal .control-group {
        width: 100%
    }

    .nnik-complaint-form .form-horizontal .controls {
        margin-left: 0
    }

    .nnik-complaint-form .form-horizontal .control-label.radio-label {
        width: 100% !important
    }
}

.nnik-complaint-form .form-thx {
    display: none
}

.nnik-complaint-form .form-thx {
    margin-top: -0.8rem;
    text-align: center;
    font-size: 1.31rem
}

.nnik-complaint-form .form-thx p:first-child {
    font-size: 1.5rem;
    margin-bottom: 1rem
}

.nnik-complaint-form .form-thx p {
    margin-bottom: 1.15rem !important
}

@media screen and (max-width: 767px) {
    .nnik-complaint-form .no-menu-section .hero-unit img {
        display: none
    }

    .nnik-complaint-form .form-horizontal .control-label {
        width: 100% !important
    }

    .nnik-complaint-form .form-horizontal .control-group input[type=text] {
        display: inline-block
    }

    .nnik-complaint-form .file-upload-wrapper {
        clear: both
    }
}

@media screen and (min-width: 768px)and (max-width: 979px) {
    .nnik-complaint-form .no-menu-section .hero-unit img {
        margin-top: 0
    }

    .nnik-complaint-form .form .control-group {
        width: 100%
    }
}

.service-form-ppk .nice-select {
    max-width: none
}

.service-form-ppk fieldset {
    margin-bottom: 3.125rem
}

.service-form-ppk .js-client-data,
.service-form-ppk .js-agreement {
    display: none
}

@media screen and (min-width: 40em)and (max-width: 63.99875em) {
    .service-form-ppk .input-wrapper.checkbox label {
        padding-top: .5rem
    }
}

@media screen and (max-width: 63.99875em) {
    .service-form-ppk .input-wrapper.checkbox label {
        max-width: calc(100% - 60px)
    }
}

.service-form-ppk .success-message {
    padding: 2rem 0 0
}

.service-form-ppk .success-message .message-header {
    font-family: "NNNittiGrotesk-Heading";
    font-size: 2.375rem;
    color: #f39100;
    margin-bottom: 2rem
}